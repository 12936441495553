import React from 'react'
const About = () => {
  return (
    <section className="relative font-['Montserrat'] px-4 md:px-36 my-10">
      <div className="flex flex-col justify-center items-center gap-y-6 ">

        <span className="title-gradient text-center text-2xl font-bold">
          About Sploitec
        </span>
        <span className="text-white text-center text-[18px] md:text-4xl font-bold">
          Empowering Digital Defense: Unveiling the Sploitec Story
        </span>

        <span className="text-white text-[16px]  md:w-[65%] text-center mb-14 md:mb-0">
          At Sploitec, cybersecurity isn't just our business; it's our crusade. With a blend of passion, expertise, and cutting-edge solutions, we stand at the vanguard of digital protection. In an era marked by rapidly evolving cyber threats, our mission is to fortify your digital landscape with robust, innovative defenses.
        </span>
      </div>
      <div className="flex  flex-col md:flex-row  gap-x-10 text-center justify-between font-bold md:mt-28 gap-y-4 relative">
        <div className=" md:text-left mt-4 font-bold  md:w-[50%] relative">
          <h1 className='title-gradient text-2xl'>
            Navigating the Cybersecurity Terrain
          </h1>
          <span className="text-white text-[18px] lg:text-[26px]">
            Guardians of the Digital Frontier: Sploitec's Comprehensive Solutions
          </span>
        </div>
        <div className="flex float-right absolute left-[30%] top-[-40%] md:left-[35%] md:top-[-30%] bg-[length:45vw] md:bg-[length:15vw]" style={{

          width: '100%',
          height: '80vh',
          backgroundImage: 'url("structure.png")',
          opacity: 0.7, // Adjust this value to change the opacity
          zIndex: 1,
          backgroundRepeat: 'no-repeat'
        }} />
        <span className='text-white text-[16px] text-center md:text-left md:w-[50%] font-light relative '>
          In the vast, interconnected expanse of our modern world, cybersecurity threats loom larger and more sophisticated than ever. Organizations worldwide grapple with the monumental task of defending their data, systems, and networks against relentless attacks. Sploitec rises to this challenge, offering a bespoke arsenal of cybersecurity services designed to counter these threats head-on. Our approach is tailored, thorough, and relentless, ensuring your defenses are as dynamic as the threats they face.
        </span>
      </div>



      <div className="grid text-white md:mt-40 mt-20">

        <span className='text-2xl font-bold text-center w-full '>
          Your <span className='title-gradient '> Trusted Ally </span> in Cyber Resiliance
        </span>
        <span className="text-white text-center text-[18px] md:text-4xl font-bold">
          Beyond Protection: Partnership with Sploitec
        </span>
        <span className="text-white text-[16px]  item-center text-center mt-6 md:mb-6 mb-14">
          In the shifting sands of the cyber threat landscape, the value of a dedicated, knowledgeable partner cannot be overstated. Sploitec transcends the role of a service provider; we are your guide, your guardian, and your ally in the digital realm. Our team invests time to understand the intricacies of your challenges, objectives, and compliance requirements, crafting solutions that resonate with your unique organizational DNA.
        </span>
        <br />
        {/* <span className="text-white text-[14px]  item-center text-center">
          Our pledge extends beyond the delivery of premier cybersecurity services. We empower you with knowledge and tools for sustained defense, fostering a culture of security awareness that endures. Through ongoing dialogue, education, and support, Sploitec equips you to confront cybersecurity challenges with unwavering confidence.
        </span> */}
      </div>

      <div className="flex float-right absolute top-[360vh] md:top-[220vh] 2xl:top-[120vh] left-[60vw] md:left-[75vw] bg-[length:30vw] md:bg-[length:12vw]" style={{
        width: '100%',
        height: '95vh',
        backgroundImage: 'url("shield.png")',
        opacity: 0.4, // Adjust this value to change the opacity
        zIndex: 1,
        backgroundRepeat: 'no-repeat'
      }}></div>


      <div className="w-[full] h-[70vh] flex flex-col justify-center items-center relative mb:40 gap-6">
        <div className="text-center text-white text-[20px] md:text-[30px] lg:[40px] relative z-20">
          <span className='text-2xl font-bold text-center w-full title-gradient'>
            Your Future, Secured with Sploitec
          </span>
          <br/>
          <span className="text-white text-center text-[18px] md:text-4xl font-bold">
            Embrace Tomorrow with Confidence
          </span>
        </div>
        <div className="text-center text-white text-[16px] font-normal my-6 relative z-20" style={{ zIndex: 20, }}>Choosing Sploitec means opting for a future where security, growth, and peace of mind converge. Together, let's build a fortress around your digital assets, ready to face the challenges of today and tomorrow. With Sploitec, you're not just protected; you're prepared.</div>
        <div className="flex flex-col md:flex-row md:gap-x-4 mt-4 gap-y-2 relative z-20">
          <div className="gradient-button px-16 py-2 cursor-pointer z-20">
            <a href="/contact">Contact Us</a>
          </div>
        </div>
        <div className="flex float-right absolute top-[-10px] left-[-40px] md:top-[-1vh] bg-[length:100vw_80vw] md:bg-[length:80vw_40vw]" style={{
          width: '100%',
          height: '100vh',
          backgroundImage: 'url("matrix.png")',
          opacity: 0.4,
          zIndex: 0, // Lowered z-index
          backgroundRepeat: 'no-repeat'
        }}></div>
      </div>
    </section>
  )
}

export default About