import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar, Footer } from './components';
import { Home } from './pages/HomeSections';
import About from './pages/About'; // Assuming you have an About component
import Contact from './pages/Contact'; // Assuming you have a Contact component
import Services from './pages/Services'; // Assuming you have a Services component



const App = () => {
  return (
    <Router>
      <div className="bg-black overflow-hidden relative h-full" style={{
        backgroundImage: 'url("site_background.png")',
        backgroundSize: 'cover',
      }}>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
