const MatrixView = () => {
    return (
        <section className="w-[100vw] h-[60vh] md:[95vh] flex flex-col justify-center items-center relative mt-[140px] md:mt-0 mb-[100px] md:mb:20">
            <div className="w-[50%] text-center text-[20px] md:text-[30px] lg:[40px] relative z-20">
                <span className="title-gradient text-center text-lg font-bold">
                    Are You Prepared for Unparalleled Cybersecurity?
                </span>
            </div>
            <div className="flex float-right absolute top-[100px] md:top-[0px] bg-[length:200vw_80vw] md:bg-[length:100vw_40vw]" style={{
                left: "2px",
                width: '100%',
                height: '100vh',
                backgroundImage: 'url("matrix.png")',
                opacity: 0.4,
                zIndex: 0, // Lowered z-index
                backgroundRepeat: 'no-repeat'
            }}></div>
            <div className="w-[50%] text-center text-white text-lg font-normal my-6 text-[12px] md:text-[18px]  relative z-20" style={{ zIndex: 20, }}>Experience the zenith of cyber defense with Sploitec's premier solutions. Our dedication to comprehensive protection is your pathway to securing the future. Embrace the peace of mind that comes from knowing your digital assets are in expert hands.</div>
            <div className="flex flex-col md:flex-row md:gap-x-4 mt-4 gap-y-2 relative z-20">
                <div className="gradient-button px-16 py-2 cursor-pointer z-20">
                    <a href="/contact">Contact Us</a>
                </div>
            </div>
        </section>
    );
}

export default MatrixView;
