import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { services_cards } from '../utils/services';
import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: Yup.string().matches(/^[0-9]+$/, "Phone Number must be only digits").required('Phone Number is required'),
    services: Yup.array().min(1, 'Please select atleast 1 service'),
    message: Yup.string(),
    attest: Yup.string().required('You must verify you are human before submitting.'),
});

const Contact = () => {
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templeteId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const form = useRef();

    const handleSubmit = ({ setSubmitting, setErrors }) => {
        try {
            emailjs
                .sendForm(serviceId, templeteId, form.current, {
                    publicKey: publicKey,
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
            setSubmitted(true);
            setSubmitting(false);
        } catch (error) {
            // If there's an error in your request, set the errors to be displayed
            setErrors({ submit: 'An error occurred when submitting the form.' });
            console.log(error)
            setSubmitting(false);
        }
    };

    const onChangeCaptcha = (value, setFieldValue) => {
        // Set the attest field's value based on the captcha's response
        // Assuming true for a successful captcha; adjust as needed
        setFieldValue('attest', value);
    };

    const [submitted, setSubmitted] = useState(false);
    return (
        <section className="relative font-['Montserrat'] px-4 md:px-36 my-10">
            <div className="flex flex-col justify-center items-center gap-y-6 mb-8 ">

                <span className="title-gradient text-center text-2xl font-bold">
                    Get in touch
                </span>
                <span className="text-white text-center text-4xl font-bold">
                    Unlock Your Cybersecurity Potential with Sploitec
                </span>

                <span className="text-white text-[14px] md:text-[14px] lg:[200px] md:w-[75%] text-center">
                    Embark on a journey to fortify your digital landscape with Sploitec's expert guidance. Our team is dedicated to understanding your needs and crafting bespoke cybersecurity solutions that ensure your assets are protected against evolving threats. Connect with us — let's discuss how we can secure your operations and foster growth in an increasingly digital world.</span>
            </div>

            {
                submitted ? (
                    <div className="gradient-box flex justify-center items-center h-[50vh] md:h-screen w-full">
                        <span className="title-gradient text-center text-2xl font-bold">
                            Thank You! Your response has been submitted
                        </span>
                    </div>
                ) : (
                    <div className="gradient-box h-full w-full  p-8 ">
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                phoneNumber: '',
                                services: '',
                                message: '',
                                attest: '',
                            }}
                            validationSchema={ContactSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, setFieldValue }) => (
                                <Form ref={form}>
                                    <div className="flex flex-col justify-center md:flex-row w-full md:px-6 py-2 gap-4 md:my-4">
                                        <div className="flex flex-col w-full gap-2">
                                            <label className='flex gap-x-2'>
                                                First Name <span style={{ color: 'red' }}>*</span>
                                                <ErrorMessage name="firstName" component="div" className='text-red-400' />
                                            </label>
                                            <Field name="firstName" placeholder="First Name" className="gradient-box px-4 py-4 bg-transparent text-left" />
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <label className='flex gap-x-2'>
                                                Last Name <span style={{ color: 'red' }}>*</span>
                                                <ErrorMessage name="lastName" component="div" className='text-red-400' />
                                            </label>
                                            <Field name="lastName" placeholder="Last Name" className="gradient-box px-4 py-4 bg-transparent text-left" />

                                        </div>
                                    </div>

                                    <div className="flex flex-col justify-center md:flex-row w-full md:px-6 py-2 gap-4 ">
                                        <div className="flex flex-col w-full gap-2">
                                            <label className='flex gap-x-2'>
                                                Email Address <span style={{ color: 'red' }}>*</span>
                                                <ErrorMessage name="email" component="div" className='text-red-400' />
                                            </label>
                                            <Field name="email" placeholder="Email Address" className="gradient-box px-4 py-4 bg-transparent text-left" />

                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <label className='flex gap-x-2'>
                                                Phone Number <span style={{ color: 'red' }}>*</span>
                                                <ErrorMessage name="phoneNumber" component="div" className='text-red-400' />
                                            </label>
                                            <Field name="phoneNumber" placeholder="Phone Number" className="gradient-box px-4 py-4 bg-transparent text-left" />

                                        </div>
                                    </div>

                                    <div role="group" aria-labelledby="checkbox-group" className='flex flex-col justify-center w-full md:px-6 py-6 gap-4 '>
                                        <div className='flex gap-x-2'>
                                            <label>Services Needed <span style={{ color: 'red' }}>*</span></label>
                                            <ErrorMessage name="services" component="div" className='text-red-400' />
                                        </div>

                                        <div className="flex flex-col md:flex-row w-full md:items-center gap-x-2 cursor-pointer md:justify-between">
                                            {
                                                services_cards.map((card, i) => (
                                                    <label className="checkbox-label flex justify-left items-center gap-4 w-auto text-left">
                                                        <Field type="checkbox" id={card.Heading} name="services" value={card.Heading}className="checkbox-custom" />
                                                        <label htmlFor={card.Heading} className="checkbox-custom-label"></label>
                                                        {card.Heading}
                                                    </label>
                                                ))
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full md:px-6 py-2 gap-x-4 ">
                                        <label className='flex gap-x-2 mb-2'>
                                            Message
                                        </label>
                                        <Field name="message" component="textarea" placeholder="Message" rows="4" className="gradient-box text-left p-4" />
                                    </div>
                                    <ErrorMessage name="attest" component="div" className='text-left foat-left text-red-400 md:px-6' />
                                    <label className="checkbox-label flex items-center gap-2 md:px-6 py-2 text-left w-full">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            onChange={(value) => onChangeCaptcha(value, setFieldValue)}
                                            
                                        />
                                    </label>
                                    <div className="gradient-button px-4 py-2 cursor-pointer my-4">

                                        <button type="submit" className='text-[14px] lg:text-[16px]' disabled={isSubmitting}>
                                            Submit
                                        </button>
                                    </div>
                                    {errors.submit && <div>{errors.submit}</div>}
                                </Form>
                            )}
                        </Formik>
                    </div >
                )
            }


        </section >
    )
}

export default Contact