export const services_cards = [
    {
        "Heading": "Vulnerability Assessments and Penetration Testing (VAPT)",
        "Detail": "Our team of experienced ethical hackers simulates real-world attacks to uncover vulnerabilities and provide actionable recommendations for mitigation.",
        "Services": [
            {
                "heading": "Web Application VAPT",
                "subHeading": "Expose and Fortify",
                "detail": "Unearth security gaps in your web applications with targeted testing designed to simulate real-world attacks. Our thorough approach identifies exploitable vulnerabilities, securing your web presence.",
                "image":"0001.png",
            },
            {
                "heading": "Mobile Application VAPT",
                "subHeading": "Secure On the Go",
                "detail": "Protect your mobile applications from emerging threats with our comprehensive mobile app VAPT. We simulate attacks to uncover vulnerabilities, ensuring your mobile platforms are robustly safeguarded.",
                "image":"0010.png",
            },
            {
                "heading": "Network VAPT",
                "subHeading": "Defend Your Network",
                "detail": "Strengthen your network against internal and external threats. Our network VAPT assesses your infrastructure to pinpoint weaknesses, offering actionable insights for enhanced network security.",
                "image":"0011.png",

            },
        ]
    },
    {
        "Heading": "External Security Assessment (ESA)",
        "Detail": "Our ESA service provides a focused assessment of your public-facing digital security, helping you understand your digital footprint and potential risks.",
        "Services": [
            {
                "heading": "Public Facing Asset Analysis",
                "subHeading": "Guard Your Digital Front",
                "detail": "Detailed analysis of your public-facing digital assets to detect vulnerabilities and secure your digital perimeter against potential cyber threats.",
                "image":"0001.png",
            },
            {
                "heading": "Reconnaissance & Intelligence Gathering",
                "subHeading": "Know Your Adversary",
                "detail": "Comprehensive reconnaissance to understand your digital footprint from an attacker’s perspective, identifying exposed assets and potential entry points.",
                "image":"0010.png",
            },
            {
                "heading": "Vulnerability Identification & Prioritization",
                "subHeading": "Strategize Your Defense",
                "detail": "Rapid identification and prioritization of vulnerabilities in your external assets, providing you with a clear roadmap for remediation and enhanced security posture.",
                "image":"0011.png",

            },
        ]
    },
    {
        "Heading": "Secure Code Review",
        "Detail": "Elevate your software's security by identifying vulnerabilities with our expert analysis, ensuring compliance and protection against cyber threats.",
        "Services": [
            {
                "heading": "Source Code Vulnerability Analysis",
                "subHeading": "Build with Security in Mind",
                "detail": "In-depth analysis of your application's source code to identify security vulnerabilities, ensuring your software is built on a foundation of security.",
                "image":"0001.png",
            },
            {
                "heading": "Compliance and Best Practices Review",
                "subHeading": "Align with Industry Standards",
                "detail": "Review your code for compliance with security best practices and industry standards, reducing risk and ensuring regulatory compliance.",
                "image":"0010.png",
            },
            {
                "heading": "Performance & Security Optimization",
                "subHeading": "Optimize for Excellence",
                "detail": "Identify opportunities not only for security enhancements but also for improving the performance and maintainability of your application code.",
                "image":"0011.png",

            },
        ]
    },
    {
        "Heading": "Tailored Cybersecurity Services",
        "Detail": "Because One Size Does Not Fit All. You can choose from our wide range of services to build a custom cybersecurity package that meets your unique needs.",
        "Services": [
            {
                "heading": "Policy Development & Implementation",
                "subHeading": "Strategic Foundations for Secure Ops",
                "detail": "Craft and implement comprehensive cybersecurity policies tailored to your organization’s needs. Our services ensure alignment with industry standards and best practices, enhancing your security posture and compliance.",
                "image":"0001.png",
            },
            {
                "heading": "Incident Response Planning",
                "subHeading": "Preparedness That Protects",
                "detail": "Develop a proactive incident response plan customized for your organization. Our approach helps you quickly identify, respond to, and recover from cyber incidents, minimizing impact and ensuring business continuity.",
                "image":"0010.png",
            },
            {
                "heading": "Security Awareness Training",
                "subHeading": "Empowering Your First Line of Defense",
                "detail": "Customized security awareness training programs designed to equip your team with the knowledge and skills to recognize and respond to cyber threats. Strengthen your human firewall with our engaging and educational training sessions.",
                "image":"0011.png",
            }
        ]
    }
]
