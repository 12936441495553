import Landing from './Landing';
// import Faqs from './Faqs';
import MatrixView from './MatrixView';
// import Portfolio from './PortfolioSection';
import ServicesOverview from './ServicesOverview';
// import TrustedPartners from './TrustedPartners';

const Home = () => (
  <>
    <Landing />
    <ServicesOverview />
    {/* <TrustedPartners /> */}
    <MatrixView />
  </>
);

export {
  Home
}