import { Link } from "react-router-dom";
const Landing = () => {
  return (
    <section className="w-full h-[80vh]">
      <div style={{
        position: 'absolute',
        top: 90,
        left: 0,
        width: '100%',
        height: '80vh',
        backgroundImage: 'url("home_background.png")',
        opacity: 0.2, // Adjust this value to change the opacity
        zIndex: 1,
        backgroundSize: '100vw',
      }}></div>
      <div className='w-[100vw] h-[70vh] md:[95vh] flex flex-col justify-center items-center' style={{ zIndex: 10, position: 'relative' }}>
        <div className="w-[50%] text-center text-[20px] md:text-[40px] lg:[50px]">
          <span className="text-white uppercase">
            Is Your Digital
          </span>
          <span className="font-black uppercase title-gradient text-center">
            {" "} Environment Secure
          </span>
          <span className="text-white font-black uppercase">?</span>
        </div>
        <div className="w-[50%] text-center text-white text-lg font-normal mt-2 text-[16px] md:text-[22px]">Securing your tomorrow, today. From global leaders to local enterprises, we're the cybersecurity partner you can trust.</div>
        <div className="flex flex-col md:flex-row md:gap-x-4 mt-6 gap-y-2">
          {/* <div className="gradient-button px-16 py-2 cursor-pointer">
            <Link to="/services">
              <span className="text-[12px] lg:text-[14px]">Our Services</span>
            </Link>
          </div> */}
          <div className="gradient-button px-16 py-2 cursor-pointer">
            <Link to="/contact">
              <span className="text-[12px] lg:text-[14px]">Get a Quote</span>
            </Link>
          </div>
        </div>
      </div>
    </section >
  );
}


export default Landing;
