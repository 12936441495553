import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom"
const ServicesOverview = () => {

    return (
        <section className="px-10 relative font-['Montserrat']">
            <div className="flex md:w-[45%] flex-col font-bold ">

                <span className="title-gradient text-left text-lg">
                    A new era in cyber security excellence
                </span>

                <span className="text-white text-[20px] md:text-[30px] lg:[40px] mt-4">
                    Comprehensive Security Solutions Tailored to Your Needs
                </span>
            </div>
            <div className="flex justify-center relative m-auto md:absolute md:top-24 md:right-28 items-center cursor-pointer text-white gap-x-4 ">
                <FaPlus />
                <Link to="/services">
                    <span>Learn More</span>
                </Link>
            </div>

            <div className="flex float-right absolute top-[90vh] md:top-[45vh] left-[60vw] md:left-[75vw] bg-[length:45vw] md:bg-[length:30vw]" style={{
                width: '100%',
                height: '95vh',
                backgroundImage: 'url("lock.png")',
                opacity: 0.7, // Adjust this value to change the opacity
                zIndex: 1,
                backgroundRepeat: 'no-repeat'
            }}></div>

            <div className="grid md:flex gap-y-4 gap-x-6 mt-12 relative md:min-h-[420px] mb-20 align-middle justify-center">
                <div className="gradient-box px-12 py-8 w-full md:w-[30%]">
                    <img src="0001.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-6">Vulnerability Assessment And Penetration Testing</h1>
                    <h2 className="text-left ml- text-[16px] italic">Precision in Every Pixel</h2>
                    <p className="text-left my-8 lg:text-[14px]">
                        Dive deep into security with our VAPT services, targeting web, mobile, and network infrastructures. Our blend of automated tools and ethical hacking expertise uncovers vulnerabilities, equipping you with strategies to shield against cyber threats effectively.
                    </p>
                </div>
                <div className="gradient-box px-12 py-8 w-full md:w-[30%]  ">
                    <img src="0010.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-6">External Security Assessment</h1>
                    <h2 className="text-left ml- text-[16px] italic">Your Digital Footprint, Safeguarded</h2>
                    <p className="text-left my-8 lg:text-[14px]">
                        Strengthen your digital defense with our focused ESA service. We scrutinize your public-facing assets, employing thorough reconnaissance and swift vulnerability detection to secure your external posture against potential attacks.
                    </p>
                </div>

                <div className="gradient-box px-12 py-8 w-full md:w-[30%] ">
                    <img src="0011.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-6">Tailored Cybersecurity Services</h1>
                    <h2 className="text-left ml- text-[16px] italic">Because One Size Does Not Fit All</h2>
                    <p className="text-left my-8 lg:text-[14px]">
                        We recognize the uniqueness of your cybersecurity needs. Sploitec offers customized solutions, adapting our vast expertise to your specific challenges. For security consulting tailored just for you, we're here to assist every step of the way.
                    </p>
                </div>
            </div>
        </section>


    )
}
export default ServicesOverview;
