import { CiLinkedin, CiInstagram } from "react-icons/ci";
// import { FaXTwitter } from "react-icons/fa6";
import { BsTwitterX, BsDot } from "react-icons/bs";
import { IoReorderThree } from "react-icons/io5";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  return (

    <nav
      className='flex justify-evenly w-[100vw] top-2 py-8 z-40 relative'
    >

      <div className='flex justify-around md:justify-center items-center w-full md:w-[33%]'>
        <Link to="/">
          <img src="navbar_logo.png" className="cursor-pointer" alt="" />
        </Link>

        <IoReorderThree className="md:hidden text-white float-right cursor-pointer h-10 w-10" onClick={() => { setShowDropDown(!showDropDown) }} />
      </div>

      <div className={`hidden md:flex md:flex-row flex-col justify-end md:justify-center w-[33%] justify-items-stretch text-[20px] md:gap-x-4 lg:gap-x-10 text-white items-center`}>
        <div className="flex items-center">
          <BsDot className={`${path.includes("about") ? "text-[#14F538]" : "text-transparent"} h-12 w-12 -mr-2`} />
          <Link to="/about">About</Link>
        </div>
        <div className="flex items-center">
          <BsDot className={`${path.includes("services") ? "text-[#14F538]" : "text-transparent"}  h-12 w-12 -mr-2`} />
          <Link to="/services">Services</Link>
        </div>
        <div className="flex items-center">
          <BsDot className={`${path.includes("contact") ? "text-[#14F538]" : "text-transparent"}  h-12 w-12 -mr-2`} />
          <Link to="/contact">Contact us</Link>
        </div>
      </div>

      <div className={`hidden md:flex justify-center w-[33%] text-whtie items-center md:gap-x-2`}>
        <a href="https://www.linkedin.com/company/sploitec/" target="_blank" rel="noreferrer"><CiLinkedin className='text-white h-8 w-8 cursor-pointer hover:text-[#006699]' /></a>
        <a href="https://www.twitter.com/sploitec" target="_blank" rel="noreferrer"><BsTwitterX className='text-white h-6 w-6 cursor-pointer hover:text-black hover:bg-white' /></a>
        <a href="https://www.instagram.com/sploitec/?hl=en" target="_blank" rel="noreferrer"><CiInstagram className='text-white h-8 w-8 cursor-pointer hover:text-[#c42773]' /></a>
      </div>

      <div className={`${showDropDown ? "flex relative" : "hidden"} md:hidden flex-col bg-black text-white justify-center text-center text-[28px] md:text-[18px] w-full md:top-20 `}>
        <div className="flex items-center justify-center">
          {path.includes("about") && <BsDot className="text-[#14F538] h-12 w-12" />}
          <a href="/about">About</a>
        </div>
        <div className="flex items-center justify-center">
          {path.includes("services") && <BsDot className="text-[#14F538] h-12 w-12" />}
          <a href="/services">Services</a>
        </div>
        <div className="flex items-center justify-center">
          {path.includes("contact") && <BsDot className="text-[#14F538] h-12 w-12" />}
          <a href="/contact">Contact us</a>
        </div>
        <div className={`flex m-auto mt-4 items-center`}>
          <a href="https://www.linkedin.com/company/sploitec/" target="_blank" rel="noreferrer"><CiLinkedin className='text-white h-8 w-8 cursor-pointer hover:text-[#006699]' /></a>
          <a href="https://www.twitter.com/sploitec" target="_blank" rel="noreferrer"><BsTwitterX className='text-white h-6 w-6 cursor-pointer hover:text-black hover:bg-white' /></a>
          <a href="https://www.instagram.com/sploitec/?hl=en" target="_blank" rel="noreferrer"><CiInstagram className='text-white h-8 w-8 cursor-pointer hover:text-[#c42773]' /></a>
        </div>
      </div>

    </nav>
  );
}
export default Navbar;
