import React from 'react'

function ServiceComponent({
    serviceCard,
    key
}) {
    return (
        <div key={key}>
            <div className="flex text-white flex-col md:flex-row text-center justify-between font-bold md:mt-8 ">
                <span className="text-[20px] lg:text-[30px] md:text-left font-bold  md:w-[50%] title-gradient">
                    {serviceCard?.Heading}
                </span>
                <span className='text-[16px] md:text-right md:w-[50%] font-light'>
                    {serviceCard?.Detail}
                </span>
            </div>

            <div className="grid md:flex justify-center gap-y-4 gap-x-6 mt-12 relative mb-20">
                {serviceCard.Services.map((s, i) => 
                    <div className="gradient-box px-12 py-8 w-full md:w-[33%]" key={i}>
                        <img src={s.image} alt="" className="ml-8" />
                        <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-6">{s.heading}</h1>
                        <h2 className="text-left text-[16px] leading-4 italic mt-2">{s.subHeading}</h2>
                        <p className="text-left my-8 lg:text-[14px]">
                            {s.detail}
                        </p>
                    </div>
                )}
                {/* <div className="gradient-box px-12 py-8 w-full md:w-[33%]">
                    <img src="0001.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-6">Source Code Vulnerability Analysis</h1>
                    <h2 className="font-bold text-left text-[16px] leading-4 italic mt-2">Build with Security in Mind</h2>
                    <p className="text-left my-8 lg:text-[14px]">
                        In-depth analysis of your application's source code to identify security vulnerabilities, ensuring your software is built on a foundation of security.
                    </p>
                </div>
                <div className="gradient-box px-12 py-8 w-full md:w-[33%]">
                    <img src="0010.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-5">Compliance and Best Practices Review</h1>
                    <h1 className="font-bold text-left text-[16px] leading-4 italic mt-2">Align with Industry Standards
                    </h1>
                    <p className="text-left my-12 lg:text-[14px]">
                        Review your code for compliance with security best practices and industry standards, reducing risk and ensuring regulatory compliance.
                    </p>
                </div>
                <div className="gradient-box px-12 py-8 w-full md:w-[33%]">
                    <img src="0011.png" alt="" className="ml-8" />
                    <h1 className="font-bold text-left -mt-4 ml- text-[24px] leading-5">Performance & Security Optimization</h1>
                    <h1 className="font-bold text-left text-[16px] leading-4 italic mt-2">Optimize for Excellence</h1>
                    <p className="text-left my-12 lg:text-[14px]">
                        Identify opportunities not only for security enhancements but also for improving the performance and maintainability of your application code.
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default ServiceComponent