import React from 'react'
import { services_cards } from '../utils/services'
import ServiceComponent from '../components/ServiceComponent';

const Services = () => {
  return (
    <section className="relative font-['Montserrat'] px-4 md:px-36 my-10">
      <div className="flex flex-col justify-center items-center gap-y-6 mb-20 ">

        <span className="title-gradient text-center text-2xl font-bold">
          Sploitec Services
        </span>
        <span className="text-white text-center text-4xl font-bold">
          Fortifying Your Digital Defense: Explore Sploitec's Cybersecurity Solutions
        </span>

        <span className="text-white text-[16px] md:w-[65%] text-center">
          Dive into Sploitec's world of cutting-edge cybersecurity solutions, meticulously designed to safeguard your digital terrain across the globe. Our services stand as a testament to our commitment to excellence, offering tailored protection against the evolving landscape of cyber threats.
        </span>
      </div>
    


      <div className="flex float-right absolute md:top-[130vh] 2xl:top-[60vh] bg-[length:45vw] md:bg-[length:25vw]" style={{
        left: "2vw",
        width: '100%',
        height: '80vh',
        backgroundImage: 'url("lock.png")',
        opacity: 0.7, // Adjust this value to change the opacity
        zIndex: 1,
        backgroundRepeat: 'no-repeat'
      }}>
      </div>

      {
        services_cards?.map((service_card, i) => {
          return (
            <ServiceComponent
              key={i}
              serviceCard={service_card}
            />
          )
        })
      }
      <div className="flex float-right absolute top-[360vh] md:top-[250vh] 2xl:top-[10vh] left-[60vw] md:left-[75vw] bg-[length:30vw] md:bg-[length:12vw]" style={{
        width: '100%',
        height: '95vh',
        backgroundImage: 'url("shield.png")',
        opacity: 0.4, // Adjust this value to change the opacity
        zIndex: 1,
        backgroundRepeat: 'no-repeat'
      }}></div>


      <div className="grid text-white md:mt-20 mt-20">
        <span className='text-[20px] md:text-[30px] font-bold text-center w-full title-gradient'>
          Elevate Your Cybersecurity with Sploitec
        </span>
        <br />
        <span className="text-white text-[16px] item-center text-center my-10">
          Step into a secure future where your digital assets are protected, and your business thrives with peace of mind. At Sploitec, we don't just offer services; we deliver cybersecurity resilience that propels your growth and prepares you for the challenges ahead. Together, we can build a secure tomorrow.
        </span>
      </div>


    </section>
  )
}

export default Services